<template>
  <div>
    <div class="infoPage">
      <div class="overviewCommand_detail">
        <el-form label-width="240px" ref="stepOneForm" :model="stepOneForm" :rules="rules">
          <el-form-item v-for="item of stepOneColumns" :key="item.title" :label="item.title" :prop="item.code">
            <template v-if="item.type === 1">
              <div style="display: flex;">
                <el-input v-model="stepOneForm[item.code]" :disabled="!item.editable" placeholder="请输入"></el-input>
                <el-button type="primary" v-if="item.code === 'stadiumAddress'" style="margin-left: 10px"
                  @click="showMap">地图选点</el-button>
              </div>
            </template>
            <template v-if="item.type === 3">
              <f-image-upload v-model="stepOneForm[item.code]" :width="'150px'" :height="'150px'"></f-image-upload>
            </template>
            <template v-if="item.type === 2">
              <el-select v-model="stepOneForm[item.code]">
                <el-option v-for="ops in item.propertyOptionList" :key="ops.value" :label="ops.label" :value="ops.value">
                </el-option>
              </el-select>
            </template>
          </el-form-item>
          <el-form-item label="赛事开始日:">
            <template>
              <el-date-picker v-model="stepOneForm.beginDate" type="date" placeholder="请选择"
                value-format="yyyy-MM-dd"></el-date-picker>
            </template>
          </el-form-item>
          <el-form-item label="赛事结束日:">
            <template>
              <el-date-picker v-model="stepOneForm.endDate" type="date" placeholder="请选择"
                value-format="yyyy-MM-dd"></el-date-picker>
            </template>
          </el-form-item>
          <el-form-item label="报名开始日:">
            <template>
              <el-date-picker v-model="stepOneForm.signBeginDate" type="date" placeholder="请选择"
                value-format="yyyy-MM-dd"></el-date-picker>
            </template>
          </el-form-item>
          <el-form-item label="报名结束日:">
            <template>
              <el-date-picker v-model="stepOneForm.signEndDate" type="date" placeholder="请选择"
                value-format="yyyy-MM-dd"></el-date-picker>
            </template>
          </el-form-item>
        </el-form>
      </div>
      <div class="overviewCommand_detail">
        <el-form label-width="240px" ref="stepThreeForm" :model="stepThreeForm" :rules="rules">
          <el-form-item label="球队分组数量：">
            <el-input class="inputPrice" placeholder="请输入球队分组数量" v-model="stepThreeForm.teamGroupCount"
              style="width: 30%;">
              <i slot="suffix" class="dw">/支</i></el-input>
          </el-form-item>
          <el-form-item label="球员分组数量:">
            <el-input class="inputPrice" placeholder="请输入球员分组数量" v-model="stepThreeForm.teamPlayerCount"
              style="width: 30%;">
              <i slot="suffix" class="dw">/人</i></el-input>
          </el-form-item>
          <el-form-item label="比赛场数：">
            <el-input class="inputPrice" placeholder="请输入比赛场数" v-model="stepThreeForm.totalGame" style="width: 30%;">
              <i slot="suffix" class="dw">/场</i></el-input>
          </el-form-item>
          <el-form-item label="赛制：">
            <el-select v-model="stepThreeForm.matchFormat">
              <el-option v-for="item in matchFormat" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场制：">
            <el-select v-model="stepThreeForm.matchType">
              <el-option v-for="item in matchType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组别：">
            <el-select v-model="stepThreeForm.matchGroup">
              <el-option v-for="item in matchGroup" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form label-width="240px" ref="stepFourForm" :model="stepFourForm" :rules="rules">
          <el-form-item label="联系方式:">
            <el-input v-model="stepFourForm.contact" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="报名方式：">
            <el-select v-model="stepFourForm.entryType">
              <el-option v-for="item in entryOption" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <div v-if="this.stepFourForm.entryType == 0">
            <el-form-item label="报名费:">
              <el-input class="inputPrice" placeholder="请输入报名费" v-model="stepFourForm.entryFee" style="width: 30%;">
                <i slot="suffix" class="dw">￥/队</i></el-input>
              <div class="promptText">* 报名费将于报名截止日可发起结算</div>
            </el-form-item>
            <el-form-item label="保证金:">
              <el-input class="inputPrice" placeholder="请输入保证金" v-model="stepFourForm.earnestMoney" style="width: 30%;">
                <i slot="suffix" class="dw">￥/队</i></el-input>
              <div class="promptText">* 保证金需赛事方在比赛结束后线下结算给球队方</div>
            </el-form-item>
            <el-form-item label="最少报名球队:">
              <el-input class="inputMin" v-model="stepFourForm.minSignTeam" style="width: 30%;">
                <i slot="suffix" class="dw">/支</i></el-input>
            </el-form-item>
            <el-form-item label="最多报名球队:">
              <el-input class="inputMax" v-model="stepFourForm.maxSignTeam" style="width: 30%;">
                <i slot="suffix" class="dw">/支</i></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div class="btn-com">
      <el-button type="primary" class="save-btn" @click="handleSave">保存
      </el-button>
    </div>
    <!-- 地图弹窗 -->
    <el-dialog :visible.sync="showMapDialog" v-if="showMapDialog" title="地图选点">
      <Map @map-submit="mapSubmit" :address="stepOneForm.stadiumAddress"></Map>
    </el-dialog>
  </div>
</template>

<script>
import Map from '@/views/eventManage/components/Map';
import FImageUpload from '@/common/components/Upload/f-image-upload';
export default {
  components: {
    Map,
    FImageUpload,
  },
  /**
   * 表单属性采用配置方式， 1:输入框  2:下拉选择  3:图片上传  4:日期选择
   */
  data() {
    return {
      disabled: false,
      matchList: [],
      title: '',
      dialogVisible: false,
      showMapDialog: false,
      entryOption: [
        {
          value: 0,
          label: "自费制"
        },
        {
          value: 1,
          label: "邀请制"
        },
      ],
      matchFormat: [
        {
          value: 0,
          label: '联赛-单循环',
        },
        {
          value: 1,
          label: '联赛-双循环',
        },
        {
          value: 2,
          label: '杯赛-小组赛+淘汰赛',
        },
        {
          value: 3,
          label: '杯赛-淘汰赛',
        },
      ],
      matchType: [
        {
          value: 5,
          label: '五人制',
        },
        {
          value: 6,
          label: '六人制',
        },
        {
          value: 7,
          label: '七人制',
        },
        {
          value: 8,
          label: '八人制',
        },
        {
          value: 9,
          label: '九人制',
        },
        {
          value: 10,
          label: '十人制',
        },
        {
          value: 11,
          label: '十一人制',
        },
      ],
      matchGroup: [
        {
          value: 1,
          label: '成人组',
        },
        {
          value: 2,
          label: 'U8组',
        },
        {
          value: 3,
          label: 'U12组',
        },
        {
          value: 4,
          label: 'U15组',
        },
        {
          value: 5,
          label: '4个分组',
        },
      ],
      rules: {
        matchLogo: [{ required: true, message: '请上传', trigger: 'change' }],
        matchTitle: [{ required: true, message: '请输入', trigger: 'blur' }],
        organizer: [{ required: true, message: '请输入', trigger: 'blur' }],
        city: [{ required: true, message: '请输入', trigger: 'change' }],
        stadium: [{ required: true, message: '请输入', trigger: 'blur' }],
        stadiumAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
        beginDate: [{ required: true, message: '请选择', trigger: 'change' }],
        endDate: [{ required: true, message: '请选择', trigger: 'change' }],
        signBeginDate: [{ required: true, message: '请选择', trigger: 'change' }],
        signEndDate: [{ required: true, message: '请选择', trigger: 'change' }],

        matchType: [{ required: true, message: '请选择', trigger: 'change' }],
        teamGroupCount: [{ required: true, message: '请输入', trigger: 'blur' }],
        teamPlayerCount: [{ required: true, message: '请输入', trigger: 'blur' }],
        totalGame: [{ required: true, message: '请输入', trigger: 'blur' }],


        matchGroup: [{ required: true, message: '请选择', trigger: 'change' }],
        entryType: [{ required: true, message: '请选择', trigger: 'change' }],
        entryFee: [{ required: true, message: '请输入', trigger: 'change' }],
        contact: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      stepOneForm: {
        matchLogo: '',
        matchTitle: '',
        organizer: '',
        city: '',
        stadium: '',
        stadiumAddress: '',
        matchType: '',
        beginDate: '',
        endDate: '',
        signBeginDate: '',
        signEndDate: '',
      },
      stepOneColumns: [
        { title: '赛事logo（建议1:1上传）：', type: 3, code: 'matchLogo', editable: true },
        { title: '赛事名称：', type: 1, code: 'matchTitle', editable: true },
        { title: '主办单位：', type: 1, code: 'organizer', editable: true },
        { title: '举办城市：', type: 1, code: 'city', editable: true },
        { title: '承办球场：', type: 1, code: 'stadium', editable: true },
        { title: '球场地址：', type: 1, code: 'stadiumAddress', editable: false },
        {
          title: '赛事类型：',
          type: 2,
          code: 'matchType',
          propertyOptionList: [
            {
              value: 1,
              label: '公开赛',
            },
            {
              value: 2,
              label: '官方赛',
            },
            {
              value: 3,
              label: '校园赛',
            },
            {
              value: 4,
              label: '城市杯',
            },
            {
              value: 5,
              label: '球场联赛',
            },
          ],
        }
      ],
      stepThreeForm: {
        matchFormat: '',
        matchType: '',
        matchGroup: '',
        teamGroupCount: '',
        teamPlayerCount: '',
        totalGame: '',
      },
      stepFourForm: {
        contact: '',
        entryType: '',
        entryFee: '',
        earnestMoney: '',
        minSignTeam: '',
        maxSignTeam: ''
      },
      matchData: {}
    };
  },
  mounted() {
    this.getMatchDetail()
  },
  methods: {
    // 获取赛事列表
    async getMatchDetail() {
      const { res } = await this.$http.get(`/Match/MatchInfo/${this.$route.query.matchId}`);
      this.stepOneForm = res.data.info;
      console.log(1111, this.stepOneForm.beginDate)
      this.stepThreeForm = res.data.formatInfo;
      this.stepFourForm = res.data.extInfo;
    },
    next() {
      if (this.currentStep === 1) {
        this.currentStep += 1;
        return;
      }
      if (this.currentStep === 2) {
        this.currentStep += 1;
        return;
      }
      if (this.currentStep === 3) {
        this.currentStep += 1;
      }
    },
    // 保存方法，第四步校验成功后，拿到所有表单的信息一起保存
    handleSave() {
      this.$refs.stepFourForm.validate((valid) => {
        if (valid) {
          this.matchData.infoRequest = this.stepOneForm
          this.matchData.formatInfoReqeust = this.stepThreeForm
          this.matchData.extInfoRequest = this.stepFourForm
          this.$confirm('请核实信息，确定要发布改赛事吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(async () => {
            const { res } = await this.$http.post('/Match/SaveMatch', this.matchData);
            if (res.isSuccess) {
              this.$showSuccess('发布成功');
              this.dialogVisible = false
              this.getMatchDetail();
            }
          });
        } else {
          this.$message.error("请填写完整信息！");
          return
        }
      });
    },

    showMap() {
      this.showMapDialog = true;
    },
    // 选择地址
    mapSubmit(location, address) {
      console.log(location, address);
      this.stepOneForm.stadiumAddress = address;
      this.stepOneForm.longitude = location.lng;
      this.stepOneForm.latitude = location.lat;
      this.showMapDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.infoPage {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  background-color: #fff;

  .bread-crumb {
    height: 30px;
  }

  .overviewCommand_detail {
    width: 50%;
  }
}

.promptText {
  width: 90%;
  color: #134f7a;
  opacity: 0.6;
}

.wpct30 {
  width: 30%;
}

.btn-com {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-left: 240px;

  .save-btn {
    width: 120px;
  }
}

.add-btn {
  position: absolute;
  right: 50px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
