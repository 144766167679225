<!-- 赛事信息 -->
<template>
    <div>
        <div class="group-name">
            <div class="group"></div>
            <div class="name">赛事</div>
        </div>
        <div class="selectGroup">
            <span>选择查看的信息类别:</span>
            <el-select v-model="matchIntroTypeId" placeholder="请选择" style="margin-left: 20px;" @change="changeType">
                <el-option v-for="item in matchIntroList" :key="item.matchIntroTypeId" :label="item.introTypeName"
                    :value="item.matchIntroTypeId">
                </el-option>
            </el-select>
        </div>
        <div style="margin-top: 20px;">
            <el-table :data="matchContent" border stripe highlight textcenter style="width: 100%">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column prop="info" label="赛事内容" align="center"></el-table-column>
                <el-table-column label="操作" style="width: 100%; white-space: nowrap" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editMatchContent(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="operate-btn-com">
                <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/60-xinzeng.png" title="新增"
                    style="width: 25px; height: 25px" @click="addContent()" />
            </div>
        </div>
        <div class="group-name" style="margin-top: 30px;">
            <div class="group"></div>
            <div class="name">赞助商</div>
        </div>
        <div class="sponsor">
            <el-table :data="sponsorData" style="width: 100%" :header-cell-style="{
                background: '#134f7a',
                color: '#fff',
                fontSize: '14px',
                height: '40px',
                width: '100%',
            }">
                <el-table-column type="index" label="编号" align="center">
                </el-table-column>
                <el-table-column prop="sponsorLogo" label="logo" align="center">
                    <template slot-scope="scope">
                        <div class="uploadPic" align="center">
                            <el-image :src="scope.row.sponsorLogo" style="width: 80px;height: 80px;"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="sponsorName" label="赞助商名称"></el-table-column>
                <el-table-column align="center" prop="sponsorType" label="规格">
                    <template slot-scope="scope">
                        <span v-if="scope.row.sponsorType == 1">主办方</span>
                        <span v-if="scope.row.sponsorType == 2">冠名赞助商</span>
                        <span v-if="scope.row.sponsorType == 3">赛事赞助商</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" style="width: 100%; white-space: nowrap" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editSponsor(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="add">
                <el-image src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/60-xinzeng.png"
                    style="width:20px;height:20px" @click="addSponsor"></el-image>
            </div>
        </div>
        <div class="group-name" style="margin-top: 30px;">
            <div class="group"></div>
            <div class="name">轮播图</div>
        </div>
        <div class="sponsor">
            <el-table :data="posterData" style="width: 100%" :header-cell-style="{
                background: '#134f7a',
                color: '#fff',
                fontSize: '14px',
                height: '40px',
                width: '100%',
            }">
                <el-table-column prop="matchPosterId" label="海报id" align="center">
                </el-table-column>
                <el-table-column prop="sponsorLogo" label="海报图片" align="center">
                    <template slot-scope="scope">
                        <div class="posterPic" align="center">
                            <el-image :src="scope.row.posterPic" style="width: 80px;height: 80px;"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
                <el-table-column label="操作" style="width: 100%; white-space: nowrap" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="deletePoster(scope.row)">删除海报</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="add">
                <el-image src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/60-xinzeng.png"
                    style="width:20px;height:20px" @click="addPoster"></el-image>
            </div>
        </div>
        <!-- 新增赛事 -->
        <el-dialog :visible.sync="dialogVisible" :title="title" width="40%" @close="closeDialog">
            <el-form ref="form" :model="form" label-width="80px" class="infoForm">
                <el-form-item label="赛事内容">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.info">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveContent">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 新增赞助商 -->
        <el-dialog :visible.sync="sponsorVisible" :title="sponsorTitle" width="40%" @close="closeSponsorDialog">
            <el-form ref="form" :model="sponsorForm" label-width="120px" class="infoForm">
                <el-form-item label="赞助商logo">
                    <f-image-upload v-model="sponsorForm.sponsorLogo" @input="changeLogo" style="margin-left: 0"
                        :width="'80px'" :height="'80px'"></f-image-upload>
                </el-form-item>
                <el-form-item label="赞助商名称">
                    <el-input placeholder="请输入内容" v-model="sponsorForm.sponsorName"></el-input>
                </el-form-item>
                <el-form-item label="赞助商规格">
                    <el-select v-model="sponsorForm.sponsorType" placeholder="请选择规格">
                        <el-option v-for="item in sponsorOption" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveSponsor">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 新增海报 -->
        <el-dialog :visible.sync="posterVisible" :title="posterTitle" width="40%" @close="closePosterDialog">
            <el-form ref="form" :model="posterForm" label-width="120px" class="infoForm">
                <el-form-item label="海报图片">
                    <f-image-upload v-model="posterForm.posterPic" @input="changePosterPic" style="margin-left: 0"
                        :width="'80px'" :height="'80px'"></f-image-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="posterVisible = false">取 消</el-button>
                <el-button type="primary" @click="savePoster">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import FImageUpload from '@/common/components/Upload/f-image-upload.vue';
export default {
    components: { FImageUpload },
    data() {
        return {
            matchContent: [],
            form: {},
            sponsorOption: [{
                value: 1,
                label: "主办方"
            },
            {
                value: 2,
                label: "冠名赞助商"
            },
            {
                value: 3,
                label: "赛事赞助商"
            }],
            sponsorData: [],
            matchIntroList: [],
            matchIntroTypeId: '',
            title: "",
            sponsorTitle: "",
            posterTitle: "",
            isActive: true,
            dialogVisible: false,
            sponsorVisible: false,
            posterVisible: false,
            sponsorForm: {},
            posterData: [],
            posterForm: {}
        };
    },
    mounted() {
        this.getIntroTypeList()
        this.getSponsorList()
        this.getPosterList()
    },
    methods: {
        // 获取分类列表
        async getIntroTypeList() {
            const { res } = await this.$http.get(`/Match/MatchIntroTypeList/${this.isActive}`);
            this.matchIntroList = res.data || [];
            if (this.matchIntroList.length) {
                this.matchIntroTypeId = this.matchIntroList[0].matchIntroTypeId;
                console.log(1111, this.matchIntroTypeId)
            }
            this.getMatchIntroList()
        },
        // 获取赞助商列表
        async getSponsorList() {
            const { res } = await this.$http.get(`/Match/MatchSponsorList/${this.$route.query.matchId}`);
            this.sponsorData = res.data || [];
        },
        // 获取海报列表
        async getPosterList() {
            const { res } = await this.$http.get(`/Match/MatchPosterList/${this.$route.query.matchId}`);
            this.posterData = res.data || [];
        },
        // 选择分类
        changeType(val) {
            this.matchIntroTypeId = val
            this.getMatchIntroList()
        },
        // 获取分类介绍列表
        async getMatchIntroList() {
            const { res } = await this.$http.get(`/Match/MatchIntroList/${this.$route.query.matchId}/${this.matchIntroTypeId}`);
            this.matchContent = res.data || [];
        },
        // 新增赛事
        addContent() {
            this.dialogVisible = true
            this.title = "新增赛事内容"
        },
        // 修改赛事
        editMatchContent(row) {
            this.dialogVisible = true
            this.title = "修改赛事内容"
            this.form = row
        },
        // 保存赛事内容
        saveContent() {
            this.form.matchId = this.$route.query.matchId,
                this.form.matchIntroTypeId = this.matchIntroTypeId
            this.$confirm('请核实信息，确定要保存赛事内容吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                const { res } = await this.$http.post('/Match/SaveMatchIntro', this.form);
                if (res.isSuccess) {
                    this.$showSuccess('发布成功');
                    this.dialogVisible = false
                    this.getMatchIntroList();
                }
            });
        },
        // 关闭弹窗
        closeDialog() {
            this.dialogVisible = false;
            this.title = '';
            this.form = {};
        },
        // 上传图片
        changeLogo() {

        },
        //上传海报
        changeAdver() {

        },
        // 添加赞助商
        addSponsor() {
            this.sponsorVisible = true;
            this.sponsorTitle = "添加赞助商"
        },
        // 保存赞助商
        saveSponsor() {
            this.sponsorForm.matchId = this.$route.query.matchId,
                this.$confirm('请核实信息，确定要保存赛事内容吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(async () => {
                    const { res } = await this.$http.post('/Match/SaveMatchSponsor', this.sponsorForm);
                    if (res.isSuccess) {
                        this.$showSuccess('发布成功');
                        this.sponsorVisible = false
                        this.getSponsorList();
                    }
                });
        },
        //修改赞助商
        editSponsor(row) {
            this.sponsorVisible = true;
            this.sponsorForm = row;
            this.sponsorTitle = "修改赞助商"
        },
        //关闭弹窗
        closeSponsorDialog() {
            this.sponsorVisible = false;
            this.sponsorTitle = '';
            this.sponsorForm = {};
        },
        // 新增海报
        addPoster() {
            this.posterVisible = true
            this.posterTitle = "新增海报"
        },
        // 保存海报
        savePoster() {
            this.posterForm.matchId = this.$route.query.matchId,
                this.$confirm('请核实信息，确定要保存该海报吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(async () => {
                    const { res } = await this.$http.post('/Match/SaveMatchPoster', this.posterForm);
                    if (res.isSuccess) {
                        this.$showSuccess('发布成功');
                        this.posterVisible = false
                        this.getPosterList();
                    }
                });
        },
        // 关闭弹窗
        closePosterDialog(){
            this.posterVisible = false;
            this.posterTitle = '';
            this.posterForm = {};
        },
        // 删除海报
        deletePoster(row){
                this.$confirm('请核实信息，确定要删除该海报吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(async () => {
                    const { res } = await this.$http.get(`/Match/DeleteMatchPoster/${row.matchPosterId}`);
                    if (res.isSuccess) {
                        this.$showSuccess('删除成功');
                        this.getPosterList();
                    }
                });  
        },
        changePosterPic() {

        }
    },
};
</script>

<style lang="scss" scoped>
.group-name {
    display: flex;
    align-items: center;

    .group {
        width: 5px;
        height: 22px;
        background-color: #134f7a;
        border-radius: 2px;
    }

    .name {
        margin-left: 10px;
        font-weight: 700;
        font-size: 20px;
    }
}

.cardinal {
    margin-top: 30px;
}

.matchDate {
    display: flex;
}

.block {
    margin-right: 60px;
}

.add {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0 10px;
    background-color: #fff;
    border-bottom: 1px solid #a5a1a1;
}

.application-price,
.bond-price {
    display: flex;
    align-items: center;
    width: 300px;
    height: 40px;
    margin: 20px;
    font-size: 18px;
    border: 1px solid #134f7a;
    border-radius: 20px;
}

.cation-title,
.bond-title {
    width: 200px;
    background-color: #134f7a;
    text-align: center;
    line-height: 40px;
    color: #fff;
    border-radius: 18px 0 0 18px;
}

.price,
.bond {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.inputPrice,
.inputBond {
    width: 60%;
    font-size: 14px;
    // background-color: #fff;
}

.matchArea {
    background-color: #fff;
    width: 60%;
    height: 40%;
    border-radius: 10px;
    padding: 20px;
}

.bondTitle {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #eb7777;
}

.bondIcon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.deduct {
    width: 90%;
    margin: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.redCard,
.yellowCard {
    margin: 30px 10px;
    display: flex;
    align-items: center;
}

.inputDeduct {
    width: 30%;
    margin-left: 10px;
    border-bottom: 1px solid #cfcfcf;
}

.inputMax,
.inputMin {
    margin-left: 10px;
    border-bottom: 1px solid #cfcfcf;
}

.promptText {
    width: 90%;
    color: #134f7a;
    opacity: 0.6;
}

.saveButton {
    background-color: #10a2db;
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
}

.statistics {
    background-color: #fff;
    width: 80%;
    height: 40%;
    border-radius: 10px;
    padding: 20px;
}

.enlist,
.cost {
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin-top: 30px;
}

.addMatch {
    margin-left: 30px;
    font-size: 16px;
    color: #000;
}

.feeArea {
    background-color: #fff;
    width: 60%;
    height: 40%;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}

.feeSet {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.introduce-area {
    display: flex;
    justify-content: space-around;

    .match-info,
    .match-reward {
        width: 40%;
    }
}

.describe-area {
    margin-top: 60px;
    display: flex;
    justify-content: space-around;

    .match-info,
    .match-reward {
        width: 40%;
    }
}

.group-name {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .group {
        width: 5px;
        height: 20px;
        background-color: #134f7a;
        border-radius: 2px;
    }

    .name {
        margin-left: 10px;
        font-weight: 400;
        font-size: 16px;
    }
}

.operate-btn-com {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px;
    border-bottom: 1px solid #333;

    img {
        cursor: pointer;
    }
}

.schedule-table {
    flex: 1;

    /deep/ .el-table__body-wrapper {
        overflow-y: auto;
        height: calc(100% - 48px);
    }
}

.sponsor {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sponsorName,
    .sponsorType {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }
}

.submit-btn {
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #134f7a;
    border: none;
}
</style>