<template>
    <div>
        <div style="margin-top: 20px;">
            <span>选择日期:</span>
            <el-date-picker v-model="pageInfo.day" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                style="margin-left: 20px;" @change="changeDate">
            </el-date-picker>
        </div>
        <div class="teamContent">
            <el-row>
                <el-col :span="4" v-for="item in courseData" :key="item.courseId" :offset="1">
                    <div @click="matchCourse(item)">
                        <el-card shadow="hover" class="teamCard">
                            <div class="date">{{ item.matchDate }}</div>
                            <div class="time">
                                <span>{{ item.matchTime }}</span>
                            </div>
                            <div class="matchInfo">
                                <span class="team">{{ item.homeTeamName }}</span>
                                <el-image
                                    src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/591-vs.png"></el-image>
                                <span class="team">{{ item.awayTeamName }}</span>
                            </div>
                            <div class="round">
                                <span>{{ item.round }}</span>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
            <div style=" margin-top: 10px; padding: 10px; text-align: right">
                <el-pagination background layout="sizes, prev, pager, next, jumper" :current-page="pageInfo.page"
                    :page-sizes="[10, 20, 30, 40]" :page-size="pageInfo.pageSize" :total="pageInfo.count"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageInfo: {
                page: 1,
                pageSize: 12,
                count: 0,
                day: ''
            },
            matchInfo: {},
            courseData: [],
        };
    },
    mounted() {
        this.matchInfo.matchId = this.$route.query.matchId
        this.getMatchScheduleList();
    },
    methods: {
        // 头部组件切换比赛，加载接口数据
        // changeMatch(match) {
        //     this.matchInfo = match;
        //     this.getMatchScheduleList();
        // },
        // 获取赛程
        async getMatchScheduleList() {
            const params = {
                page: this.pageInfo.page,
                pageSize: this.pageInfo.pageSize,
                day: this.pageInfo.day
            };
            const { res } = await this.$http.get(`/Match/MatchScheduleList/${this.matchInfo.matchId}`, { params });
            this.courseData = res.data.list || [];
            this.pageInfo.count = res.data.total;

        },
        // 选择日期
        changeDate() {
            this.getMatchScheduleList()
        },
        // 分页
        handleSizeChange(val) {
            this.pageInfo.pageSize = val;
            this.getMatchScheduleList();
        },
        handleCurrentChange(val) {
            this.pageInfo.page = val;
            console.log(1111, val)
            this.getMatchScheduleList();
        },
        matchCourse(item) {
            this.$router.push({
                name: 'OutLists',
                query: {
                    matchId:this.matchInfo.matchId,
                    matchScheduleId: item.matchScheduleId,
                    homeTeamName: item.homeTeamName,
                    homeTeamId: item.homeTeamId,
                    awayTeamName: item.awayTeamName,
                    awayTeamId: item.awayTeamId,
                    status:item.status
                },
            });
        }
    },
};
</script>
<style lang="scss" scoped>
.league-match {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: linear-gradient(#7ECB2B, #4ebbd6);
    padding: 20px;
    border-radius: 20px;

    .leagueImage {
        width: 65px;
        height: 65px;
    }

    .leagueInfo {
        display: flex;
        align-items: center;
    }

    .teamInfo {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
    }

    .lengueName {
        font-size: 23px;
    }

    .application {
        margin-top: 10px;
        font-size: 13px;
        display: flex;
        align-items: center;
    }

    .teamNum {
        color: #2e10db;
    }

    .feeType {
        margin-left: 20px;
        color: rgb(224, 205, 120);
    }

}

.teamContent {
    margin-top: 30px;

    .teamCard {
        margin-top: 15px;

        .date {
            font-size: 19px;
            font-weight: bold;
        }

        .time {
            display: flex;
            justify-content: center;
            color: #d1b518;
            margin-top: 10px;
            height: 20px;
        }

        .team {
            width: 120px;
            display: flex;
            justify-content: center;
        }

        .matchInfo {
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 16px;
        }

        .el-image {
            width: 50px;
            height: 50px;
        }

        .round {
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }
    }

}
</style>