<!-- 比赛管理 -->
<template>
    <div>
        <div style="display: flex;">
            <div style="margin-top: 20px;flex: 1;">
                <span>选择日期:</span>
                <el-date-picker v-model="pageInfo.day" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                    style="margin-left: 20px;" @change="changeDate">
                </el-date-picker>
            </div>
            <el-button class="add_btn" type="primary" @click="addMatch">新增</el-button>
        </div>
        <div class="mt-3">
            <el-table :data="tableData" style="width: 100%" :header-cell-style="{
                background: '#134f7a',
                color: '#fff',
                fontSize: '14px',
                height: '40px',
                width: '100%',
            }">
                <el-table-column type="index" label="序号" align="center">
                </el-table-column>
                <el-table-column prop="round" label="轮次名称" align="center"></el-table-column>
                <el-table-column prop="matchDate" label="日期" align="center"></el-table-column>
                <el-table-column prop="matchTime" label="时间" align="center"></el-table-column>
                <el-table-column prop="homeTeamName" label="主队" align="center"></el-table-column>
                <el-table-column prop="homeTeamShirt" label="主队球衣" align="center"></el-table-column>
                <el-table-column prop="awayTeamName" label="客队" align="center"></el-table-column>
                <el-table-column prop="awayTeamShirt" label="客队球衣" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editCourse(scope.row)">修改</el-button>
                        <!-- <el-button type="text" @click="deletePlayer(scope.row)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <div style="background-color: #fff; margin-top: 10px; padding: 10px; text-align: right">
                <el-pagination background layout="sizes, prev, pager, next, jumper" :current-page="pageInfo.page"
                    :page-sizes="[10, 20, 40, 100]" :page-size="pageInfo.pageSize" :total="pageInfo.count"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisibleMatch" :title="title" width="60%" @close="closeDialog">
            <el-form ref="form" :model="form" label-width="100px" class="infoForm">
                <div class="group-name">
                    <div class="group"></div>
                    <div class="name">基本信息</div>
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="开赛日期">
                            <el-date-picker value-format="yyyy-MM-dd" v-model="form.matchDate" type="date"
                                placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开赛时间">
                            <el-time-picker value-format="HH:mm:ss" @blur="$forceUpdate()" v-model="form.beginTime"
                                :picker-options="{
                                    selectableRange: '00:00:00 - 23:30:00'
                                }" placeholder="选择时间点">
                            </el-time-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="承办球场">
                            <div style="display: flex;align-items: center;">
                                <el-input v-model="form.stadium" placeholder="请选择承办球场" :disabled="true"></el-input>
                                <el-image @click="changePosition" style="width: 25px;height: 25px;margin-left: 10px;"
                                    src="https://img1.baidu.com/it/u=2019955101,1203599064&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1697562000&t=a2875a8d423a739e066356057a682120"></el-image>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="场地名称">
                            <el-input v-model="form.venue" placeholder="请输入场地名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="轮次名称">
                            <el-input v-model="form.round" placeholder="请输入场地名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="裁判姓名">
                            <el-input v-model="form.referee" placeholder="请输入裁判姓名"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="比赛用球">
                            <el-input v-model="form.matchBall" placeholder="请输入比赛用球"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="group-name">
                    <div class="group"></div>
                    <div class="name">球队信息</div>
                </div>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="主队组别">
                            <el-select v-model="form.teamGroupId" @change="changeTeamGroupName" >
                                <el-option v-for="item in teamGroup" :key="item.teamGroupId" :label="item.teamGroupName"
                                    :value="item.teamGroupId"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="主队选择">
                            <el-select v-model="form.homeTeamName" @change="homeTeamChange">
                                <el-option v-for="item in homeTeamList" :key="item.teamId" :label="item.teamName"
                                    :value="item.teamName"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="主队球衣颜色">
                            <el-select v-model="form.homeTeamShirt" placeholder="请选择主队球衣颜色">
                                <el-option v-for="item in Jersey" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="客队组别">
                            <el-select v-model="form.awayGroupId" @change="changeAwayTeamGroup"  >
                                <el-option v-for="item in teamGroup" :key="item.teamGroupId" :label="item.teamGroupName"
                                    :value="item.teamGroupId"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客队选择">
                            <el-select v-model="form.awayTeamName" @change="awayTeamChange">
                                <el-option v-for="item in awayTeamList" :key="item.teamId" :label="item.teamName"
                                    :value="item.teamName"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客队球衣颜色">
                            <el-select v-model="form.awayTeamShirt" placeholder="请选择客队球衣颜色">
                                <el-option v-for="item in Jersey" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleMatch = false">取 消</el-button>
                <el-button type="primary" @click="commitMatch">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 地图弹窗 -->
        <el-dialog :visible.sync="showMapDialog" v-if="showMapDialog" title="地图选点">
            <Map @map-submit="mapSubmit" :address="form.stadiu"></Map>
        </el-dialog>
    </div>
</template>

<script>
import Map from '@/views/eventManage/components/Map';
export default {
    components: {
        Map,
    },
    data() {
        return {
            pageInfo: {
                page: 1,
                pageSize: 10,
                day: "",
                count: 0,
            },
            dialogVisibleMatch: false,
            showMapDialog: false,
            formData: {},
            tableData: [],
            eventOption: [{
                value: 0,
                label: '红牌'
            },
            {
                value: 1,
                label: '黄牌'
            },
            {
                value: 2,
                label: '进球'
            },
            {
                value: 3,
                label: '助攻'
            },
            {
                value: 4,
                label: '乌龙球'
            },
            {
                value: 5,
                label: '点球'
            }],
            Jersey: [
                {
                    value: "暂无",
                    label: "暂无"
                },
                {
                    value: "红色",
                    label: "红色"
                },
                {
                    value: "橙色",
                    label: "橙色"
                },
                {
                    value: "黄色",
                    label: "黄色"
                },
                {
                    value: "绿色",
                    label: "绿色"
                },
                {
                    value: "蓝色",
                    label: "蓝色"
                },
                {
                    value: "黑色",
                    label: "黑色"
                },
                {
                    value: "紫色",
                    label: "紫色"
                }],
            form: {
                teamGroupId: '',
                awayGroupId: ''
            },
            recordData: [{}],
            homeTeamData: [],
            title: "",
            matchInfo: {},
            homeTeamList: [],
            awayTeamList: [],
            teamGroup: [],
            stepOneForm: {},
            stepThreeForm: {},
            stepFourForm: {},
            teamGroupId: '',
            awayGroupId: ''
        };
    },
    mounted() {
        this.matchInfo.matchId = this.$route.query.matchId
        this.getMatchScheduleList()
        this.getTeamGroupList();
        this.getMatchDetail();
        this.getTeamList();
        this.getAwayTeamList();
    },
    methods: {
        // 获取赛程
        async getMatchScheduleList() {
            const params = {
                page: this.pageInfo.page,
                pageSize: this.pageInfo.pageSize,
                day: this.pageInfo.day
            };
            const { res } = await this.$http.get(`/Match/MatchScheduleList/${this.matchInfo.matchId}`, { params });
            this.tableData = res.data.list || [];
            this.pageInfo.count = res.data.total;
        },
        // 选择日期
        changeDate() {
            this.getMatchScheduleList()
        },
        // 选择位置
        changePosition() {
            this.showMapDialog = true
        },
        // 获取位置
        mapSubmit(location, address) {
            console.log(111, location);
            this.form.stadium = address;
            this.form.longitude = location.lng;
            this.form.latitude = location.lat;
            this.showMapDialog = false;
        },
        // 获取赛事信息
        async getMatchDetail() {
            const { res } = await this.$http.get(`/Match/MatchInfo/${this.matchInfo.matchId}`);
            this.stepOneForm = res.data.info;
            this.stepThreeForm = res.data.formatInfo;
            this.stepFourForm = res.data.extInfo;
        },
        // 获取组别
        async getTeamGroupList() {
            const { res } = await this.$http.get(`/Match/TeamGroupList/${this.matchInfo.matchId}`);
            this.teamGroup = res.data || [];
            this.teamGroup.push({ teamGroupId: 0, teamGroupName: '暂无分组' })
        },
        // 选择主队组别
        changeTeamGroupName(val) {
            this.form.teamGroupName = this.teamGroup.find(teamGroup => {
                return teamGroup.teamGroupId === val;
            }).teamGroupName;
            this.form.teamGroupId = val
            this.getTeamList();
        },
        // 选择客队组别
        changeAwayTeamGroup(val) {
            this.form.awayGroupId = val
            this.getAwayTeamList();
        },
        // 获取主队球队
        async getTeamList() {
            if (this.form.teamGroupId == '') {
                this.teamGroupId = 0
            }else{
                this.teamGroupId = this.form.teamGroupId
            }
            const { res } = await this.$http.get(`/Match/MatchTeamList/${this.matchInfo.matchId}/${this.teamGroupId}`);
            if (res.isSuccess) {
                this.homeTeamList = res.data || [];
                this.homeTeamList.push({ teamId: 0, teamName: '待定' })
            }

        },
        // 获取客队球队
        async getAwayTeamList() {
            if (this.form.awayGroupId == '') {
                this.awayGroupId = 0
            } else {
                this.awayGroupId = this.form.awayGroupId
            }
            const { res } = await this.$http.get(`/Match/MatchTeamList/${this.matchInfo.matchId}/${this.awayGroupId}`);
            if (res.isSuccess) {
                this.awayTeamList = res.data || [];
                this.awayTeamList.push({ teamId: 0, teamName: '待定' })
            }

        },
        //选择主队
        homeTeamChange(val) {
            this.form.homeTeamId = this.homeTeamList.find(team => {
                return team.teamName === val;
            }).teamId;
        },
        //选择客队
        awayTeamChange(val) {
            this.form.awayTeamId = this.awayTeamList.find(team => {
                return team.teamName === val;
            }).teamId;
            console.log(11111, this.form.awayTeamId)
        },
        // 保存赛程
        commitMatch() {
            if (this.form.teamGroupId =='') {
                this.form.teamGroupId = 0
            }
            this.form.matchId = this.matchInfo.matchId
            this.$confirm('请核实信息，确定要发布改赛程吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                const { res } = await this.$http.post('/Match/SaveMatchSchedule', this.form);
                if (res.isSuccess) {
                    this.$showSuccess('发布成功');
                    this.dialogVisibleMatch = false
                    this.getMatchScheduleList();
                }
            });
        },
        // 删除赛事
        deletePlayer() {
            this.$confirm('确认要删除该赛事吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
        },
        // 新增赛事
        addMatch() {
            this.dialogVisibleMatch = true
            this.form.beginTime = '12:00:00';
            this.form.stadium = this.stepOneForm.stadiumAddress
            this.form.latitude = this.stepOneForm.latitude
            this.form.longitude = this.stepOneForm.longitude
            this.title = "新增赛程"
        },
        // 关闭弹窗
        closeDialog() {
            this.dialogVisibleMatch = false;
            this.title = '';
            this.form = {};
        },

        // 修改赛事
        editCourse(row) {
            this.dialogVisibleMatch = true
            this.form = this.cloneDeep(row);
            if (this.form.matchDate !== null) {
                this.form.matchDate = this.form.matchDate.slice(0, 10);
            }
            if (this.form.beginTime !== null) {
                this.form.beginTime = this.form.matchTime.slice(11, 20)
            }
            console.log(1111, this.form)
            this.title = "修改赛事"
        },
        handleSizeChange(val) {
            this.pageInfo.pageSize = val;
            this.getMatchScheduleList();
        },
        handleCurrentChange(val) {
            this.pageInfo.page = val;
            this.getMatchScheduleList();
        },

        // 深拷贝
        cloneDeep(target) {
            // 传入null，还是返回null
            if (target === null) {
                return target;
            }
            // 判断是否是对象
            if (typeof target === 'object') {
                // 兼容数组与对象
                let obj = Array.isArray(target) ? [] : {};
                for (let key in target) {
                    obj[key] = this.cloneDeep(target[key]);
                }
                return obj;
            } else {
                return target;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.scoreVisible {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
}

.teamScore {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.add {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0 10px;
    background-color: #fff;
    border-bottom: 1px solid #a5a1a1;
}

.line {
    height: 20px;
    width: 7px;
    background-color: #000;
    border-radius: 10px;
}

.title {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.saveRecord {
    width: 80px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    text-align: center;
    float: right;
    margin-bottom: 20px;
    color: #fff;
    background-color: #10aada;
}

.saveScore {
    width: 40px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 20px;
    color: #fff;
    background-color: #da9310;
}

.add_btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.group-name {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .group {
        width: 5px;
        height: 18px;
        background-color: #134f7a;
        border-radius: 2px;
    }

    .name {
        margin-left: 10px;
        font-weight: 600;
        font-size: 18px;
    }
}
</style>