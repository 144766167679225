<!-- 组别管理 -->
<template>
    <div>
        <div style="margin-top: 20px;">
            <el-table :data="groupData" border stripe highlight textcenter style="width: 100%">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column prop="teamGroupName" label="组别名称" align="center"></el-table-column>
                <!-- <el-table-column label="球队" align="center">
                    <template>
                        <el-button type="text" @click="teamList()">查看</el-button>
                    </template>
                </el-table-column> -->
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editGroup(scope.row)">修改</el-button>
                        <!-- <el-button type="text">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <div class="operate-btn-com">
                <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/60-xinzeng.png" title="新增"
                    style="width: 25px; height: 25px" @click="addGroup()" />
            </div>
        </div>
        <!-- 新增组别弹窗 -->
        <el-dialog :visible.sync="dialogVisible" :title="title" width="40%" @close="closeDialog" >
            <el-form ref="form" :model="form" label-width="80px" class="infoForm">
                <el-form-item label="组别名称">
                    <el-input v-model="form.teamGroupName" placeholder="请输入组别"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveGroup">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            groupData: [],
            dialogVisible: false,
            input: '',
            matchList: [],
            matchInfo: {},
            teamData: [],
            changeTeam: [],
            form: {},
            title: '',
        };
    },
    mounted() {
        this.matchInfo.matchId=this.$route.query.matchId
        this.getTeamGroupList();
    },
    methods: {  
        // 头部组件切换比赛，加载接口数据
        // changeMatch(match) {
        //     this.matchInfo = match;
        //     this.getTeamGroupList();
        // },
        // 获取组别
        async getTeamGroupList() {
            const { res } = await this.$http.get(`/Match/TeamGroupList/${this.matchInfo.matchId}`);
            this.groupData = res.data || [];
        },
        // 保存组别
        async saveGroup() {
            this.form.matchId = this.matchInfo.matchId
            const { res } = await this.$http.post('/Match/SaveTeamGroup', this.form);
            if (res.isSuccess) {
                this.$showSuccess('发布成功');
                this.dialogVisible = false
                this.getTeamGroupList();
            }
        },
        // 添加组别弹窗
        addGroup() {
            this.dialogVisible = true
            this.title = "添加组别"
        },
        // 修改组别
        editGroup(row) {
            this.dialogVisible = true;
            this.title = "修改组别"
            this.form = this.cloneDeep(row);
        },
        // 深拷贝
        cloneDeep(target) {
            // 传入null，还是返回null
            if (target === null) {
                return target;
            }
            // 判断是否是对象
            if (typeof target === 'object') {
                // 兼容数组与对象
                let obj = Array.isArray(target) ? [] : {};
                for (let key in target) {
                    obj[key] = this.cloneDeep(target[key]);
                }
                return obj;
            } else {
                return target;
            }
        },
        // 关闭弹窗
         closeDialog() {
            this.dialogVisible = false;
            this.title = '';
            this.form = {};
        },
    },
};
</script>
<style lang="scss" scoped>
.addMatch {
    margin-left: 30px;
    font-size: 16px;
    color: #000;
}

.operate-btn-com {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px;
    border-bottom: 1px solid #333;

    img {
        cursor: pointer;
    }
}

.teamList {
    margin: 0 30px;

    .teamItem {
        border-bottom: 1px #cccccc solid;
        padding: 10px 0;
        display: flex;
        align-items: center;

        .teamInfo {
            flex: 1;
            display: flex;
            align-items: center;
        }

        .teamImage {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .teamName {
            margin-left: 20px;
            font-size: 15px;
        }
    }
}
</style>