<template>
  <div class="map-wrap">
    <div id="r-result" style="margin-bottom: 10px">
      请输入地点：
      <el-input
        v-model="first_address"
        v-if="firstLoad"
        @focus="firstAddRessFocus"
        style="width:200px; margin-right:10px;"
      ></el-input>
      <el-input
        ref="input"
        v-show="!firstLoad"
        id="suggestId"
        v-model="address_detail"
        style="width:200px; margin-right:10px;"
      ></el-input>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
    <div style="margin-bottom: 10px">
      当前经纬度：
      <el-input
        style="width:95px; margin-right:10px"
        disabled
        v-model="location.lng"
      ></el-input>
      <el-input style="width:95px;" disabled v-model="location.lat"></el-input>
    </div>
    <div
      id="searchResultPanel"
      style="border:1px solid #C0C0C0;width:150px;height:auto; display:none"
    ></div>
    <div id="container" class="map" style="width:100%; height:600px"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    address: {
      type: String,
      default: '北京市天安门'
    }
  },

  data() {
    return {
      map: null,
      first_address: '',
      address_detail: '',
      location: {},
      firstLoad: true
    };
  },

  methods: {
    firstAddRessFocus() {
      this.firstLoad = false;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },

    // 第一次打开dialog获取默认地址经纬度，地图标记
    initFirstAddress() {
      const localSearch = new BMap.LocalSearch(this.map);
      localSearch.search(this.first_address);

      localSearch.setSearchCompleteCallback(searchResult => {
        const poi = searchResult.getPoi(0);
        if (!poi) {
          this.$emit('on-error')
          return;
        }
        this.location = poi.point;
        var marker = new BMap.Marker(
          new BMap.Point(poi.point.lng, poi.point.lat)
        );
        this.map.addOverlay(marker);
      });
    },

    init() {
      this.map = new BMap.Map('container');
      this.map.centerAndZoom(this.first_address, 22);

      if (this.firstLoad) {
        this.initFirstAddress();
      }

      var ac = new BMap.Autocomplete({
        input: 'suggestId',
        location: this.map
      });

      ac.addEventListener('onhighlight', e => {
        var str = '';
        var _value = e.fromitem.value;
        var value = '';
        if (e.fromitem.index > -1) {
          value =
            _value.province +
            _value.city +
            _value.district +
            _value.street +
            _value.business;
        }
        str =
          'FromItem<br />index = ' +
          e.fromitem.index +
          '<br />value = ' +
          value;

        value = '';
        if (e.toitem.index > -1) {
          _value = e.toitem.value;
          value =
            _value.province +
            _value.city +
            _value.district +
            _value.street +
            _value.business;
        }
        str +=
          '<br />ToItem<br />index = ' +
          e.toitem.index +
          '<br />value = ' +
          value;
        document.getElementById('searchResultPanel').innerHTML = str;
      });

      var myValue;
      ac.addEventListener('onconfirm', e => {
        //鼠标点击下拉列表后的事件
        var _value = e.item.value;
        myValue =
          _value.province +
          _value.city +
          _value.district +
          _value.street +
          _value.business;
        document.getElementById('searchResultPanel').innerHTML =
          'onconfirm<br />index = ' +
          e.item.index +
          '<br />myValue = ' +
          myValue;
        this.setPlace(myValue);
      });
    },

    setPlace(val) {
      this.address_detail = val;
      this.map.clearOverlays();
      var local = new BMap.LocalSearch(this.map, {
        //智能搜索
        onSearchComplete: myFun
      });
      var that = this;
      function myFun() {
        var position = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果
        that.map.centerAndZoom(position, 18);
        that.map.addOverlay(new BMap.Marker(position)); //添加标注
        that.$nextTick(() => {
          that.location = position;
        });
      }
      local.search(val);
    },

    handleSubmit() {
      this.$emit('map-submit', this.location, this.address_detail);
    },

    close() {
      this.map = null;
      this.address_detail = '';
      this.location = {};
    }
  },

  mounted() {
    // this.address_detail = this.address;
    this.first_address = this.address || '北京市天安门';
    this.init();
  }
};
</script>

<style lang="scss" scoped>

</style>
