<!-- 球队展示 -->
<template>
    <div>
        <div style="margin-top: 20px;" v-if="this.groupData.length">
            <span>选择组别:</span>
            <el-select v-model="teamGroupId" placeholder="请选择" @change="selectGroup" style="margin-left: 20px;">
                <el-option v-for="item in groupData" :key="item.teamGroupId" :label="item.teamGroupName"
                    :value="item.teamGroupId">
                </el-option>
            </el-select>
        </div>
        <div class="teamContent">
            <el-row>
                <el-col :span="4" v-for="item in teamList" :key="item.teamId" :offset="1">
                    <div @click="playerList(item)">
                        <el-card shadow="hover" class="teamCard">
                            <div class="player">
                                <span class="playerNum"> × {{ item.playerCount }}</span>
                            </div>
                            <div class="teamInfo">
                                <el-image :src="item.logo" class="image"></el-image>
                                <div class="team-group">
                                    <span class="teamName">{{ item.teamName }}</span>
                                    <span class="group">{{ item.teamGroupName }}</span>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            teamList: [],
            dropdownName: "万象足球杯联赛",
            matchList: [],
            matchInfo: {},
            teamGroupId: 0,
            groupData: []
        };
    },
    mounted() {
        this.matchInfo.matchId = this.$route.query.matchId
        this.getTeamGroupList()
        this.getTeamList()
    },
    methods: {
        // 获取球队
        async getTeamList() {
            const { res } = await this.$http.get(`/Match/MatchTeamList/${this.matchInfo.matchId}/${this.teamGroupId}`);
            this.teamList = res.data || [];
        },
        // 获取组别
        async getTeamGroupList() {
            const { res } = await this.$http.get(`/Match/TeamGroupList/${this.matchInfo.matchId}`);
            this.groupData = res.data || [];
            if (this.groupData.length) {
                this.groupData.push({ teamGroupId: 0, teamGroupName: '全部'})
                this.getTeamList()
            }
        },
        // 选择组别
        selectGroup() {
            this.getTeamList()
        },
        // 切换赛事
        handleCommand(command) {
            this.matchInfo = command;
        },
        // 查看球员页面
        playerList(item) {
            this.$router.push({
                name: 'PlayerManage',
                query: {
                    teamId: item.teamId,
                    matchId: this.matchInfo.matchId
                },
            });
        }
    },
};
</script>
<style lang="scss" scoped>
.teamContent {
    margin-top: 30px;

    .teamCard {
        margin-top: 15px;
    }

    .player {
        position: relative;

        .playerNum {
            right: 0;
            position: absolute;
            top: -15px;
            font-size: 16px;
            color: #134f7a;
        }

    }

    .teamInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .image {
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }

    .team-group {
        margin-top: 20px;

        .teamName {
            color: #134f7a;
            font-size: 18px;
        }

        .group {
            margin-left: 20px;
        }
    }
}
</style>