<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>赛事详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button icon="el-icon-back" type="text" title="返回" class="back" @click="backPage()"></el-button>
        <div class="league-match">
            <div class="league-info">
                <img :src="matchLogo" />
                <div class="league-detail">
                    <p>{{ matchTitle }}</p>
                </div>
            </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" topline>
            <el-tab-pane label="基本信息" name="first">
                <info-list  v-if="activeName == 'first'"></info-list>
            </el-tab-pane>
            <el-tab-pane label="赛事信息" name="second">
                <event-manage  v-if="activeName == 'second'"></event-manage>
            </el-tab-pane>
            <el-tab-pane label="组别管理" name="third">
                <group-lists  v-if="activeName == 'third'"></group-lists>
            </el-tab-pane>
            <el-tab-pane label="球队管理" name="fourth">
                <team-control  v-if="activeName == 'fourth'"></team-control>
            </el-tab-pane>
            <el-tab-pane label="球员管理" name="fifth">
                <team-list  v-if="activeName == 'fifth'"></team-list>
            </el-tab-pane>
            <el-tab-pane label="赛程信息" name="sixth">
                <match-manage   v-if="activeName == 'sixth'"></match-manage>
            </el-tab-pane>
            <el-tab-pane label="赛况管理" name="seventh">
                <course-manage  v-if="activeName == 'seventh'"></course-manage>
            </el-tab-pane>
            <el-tab-pane label="邀请码" name="eighth">
                <invitation-list  v-if="activeName == 'eighth'"></invitation-list>
            </el-tab-pane>
        </el-tabs>
    </div>
</template> 
<script>
import GroupLists from '@/views/eventManage/matchDetail/detailComponents/GroupLists';
import TeamControl from '@/views/eventManage/matchDetail/detailComponents/TeamControl';
import TeamList from '@/views/eventManage/matchDetail/detailComponents/TeamList';
import MatchManage from '@/views/eventManage/matchDetail/detailComponents/MatchManage';
import CourseManage from '@/views/eventManage/matchDetail/detailComponents/CourseManage';
import InfoList from '@/views/eventManage/matchDetail/detailComponents/InfoList';
import EventManage from '@/views/eventManage/matchDetail/detailComponents/EventManage';
import InvitationList from '@/views/eventManage/matchDetail/detailComponents/InvitationList';
export default {
    components: {
        GroupLists,
        TeamControl,
        TeamList,
        MatchManage,
        CourseManage,
        InfoList,
        EventManage,
        InvitationList
    },
    data() {
        return {
            activeName: 'first',
            matchTitle: '',
            matchLogo: '',
            matchInfo:{}
        };
    },
    mounted() {
        this.matchTitle = this.$route.query.matchTitle
        this.matchLogo = this.$route.query.matchLogo
        //接受返回的activeName
        if (this.$route.query.activeName) {
            this.activeName = this.$route.query.activeName;
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        // 返回上一页
        backPage() {
            this.$router.push({
                name: 'matchList',
            });
        },
    },

};
</script>

<style lang="scss" scoped>
.matchArea {
    margin-top: 30px;
}

.back {
    margin-top: 10px;
    font-size: 18px;
}

.league-match {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: linear-gradient(#7ecb2b, #4ebbd6);
    border-radius: 10px;

    .league-info {
        height: 65px;
        display: flex;
        align-items: center;

        img {
            width: 65px;
            height: 65px;
            margin-right: 20px;
            border-radius: 50%;
        }

        .league-detail {
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            p {
                font-size: 24px;
            }

            .detail {
                span:nth-of-type(1) {
                    color: #2e10db;
                }

                span:nth-of-type(2) {
                    color: #e0cd78;
                    margin-left: 20px;
                }
            }
        }
    }
}
</style>
