<!-- 邀请码 -->
<template>
    <div>
        <div class="add_btn">
            <el-button type="primary" @click="addCode()">新增</el-button>
        </div>
        <div style="margin-top: 20px;">
            <el-table :data="codeData" stripe highlight textcenter style="width: 100%">
                <el-table-column prop="codeId" label="编码Id" align="center"></el-table-column>
                <el-table-column prop="invitationCode" label="邀请编码" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="deleteCode(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 新增组别弹窗 -->
        <el-dialog :visible.sync="dialogVisible" title="生成邀请码" width="40%" @close="closeDialog">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" class="infoForm">
                <el-form-item label="邀请码长度" prop="codeLength">
                    <el-input v-model="form.codeLength" placeholder="请输入邀请码长度至少8位"></el-input>
                </el-form-item>
                <el-form-item label="生成数量" prop="codeCount" >
                    <el-input v-model="form.codeCount" placeholder="请输入生成数量"></el-input>
                </el-form-item>
                <el-form-item label="邀请码前缀" prop="prefixCode" >
                    <el-input v-model="form.prefixCode" placeholder="请输入邀请码前缀"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveCode">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            codeData: [],
            dialogVisible: false,
            matchInfo: {},
            form: {
                codeLength:"",
                codeCount:"",
                prefixCode:""
            },
            rules: {
                codeLength: [{ required: true, message: '请输入', trigger: 'blur' }],
                codeCount: [{ required: true, message: '请输入', trigger: 'blur' }],
            },
        };
    },
    mounted() {
        this.matchInfo.matchId = this.$route.query.matchId
        this.getInvitationCode();
    },
    methods: {
        // 获取邀请码
        async getInvitationCode() {
            const { res } = await this.$http.get(`/Match/MatchInvitationCodeList/${this.matchInfo.matchId}`);
            this.codeData = res.data || [];
        },
        // 生成邀请码
        addCode() {
            this.dialogVisible = true
        },
        // 保存生成邀请码
        async saveCode() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.form.matchId = this.matchInfo.matchId
                    this.$confirm('请核实信息，确定要生成邀请码吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(async () => {
                        const { res } = await this.$http.post('/Match/GenerateInvitationCode', this.form);
                        if (res.isSuccess) {
                            this.$showSuccess('发布成功');
                            this.dialogVisible = false
                            this.getInvitationCode();
                        }
                    });
                } else {
                    this.$message.error("请填写完整信息！");
                    return
                }
            });
        },
        // 删除邀请码
        deleteCode(row) {
            this.$confirm('请核实信息，确定要删除该邀请码吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                const { res } = await this.$http.post(`/Match/DeleteInvitationCode/${row.codeId}/${this.matchInfo.matchId}`);
                if (res.isSuccess) {
                    this.$showSuccess('删除成功');
                    this.getInvitationCode();
                }
            });
        },
        // 关闭弹窗
        closeDialog() {
            this.dialogVisible = false;
            this.form = {};
        },
    },
};
</script>

<style lang="scss" scoped>
.addMatch {
    margin-left: 30px;
    font-size: 16px;
    color: #000;
}

.operate-btn-com {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px;
    border-bottom: 1px solid #333;

    img {
        cursor: pointer;
    }
}

.add_btn {
    display: flex;
    justify-content: flex-end;
}
</style>