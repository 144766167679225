import { render, staticRenderFns } from "./CourseManage.vue?vue&type=template&id=2cf889ad&scoped=true&"
import script from "./CourseManage.vue?vue&type=script&lang=js&"
export * from "./CourseManage.vue?vue&type=script&lang=js&"
import style0 from "./CourseManage.vue?vue&type=style&index=0&id=2cf889ad&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cf889ad",
  null
  
)

export default component.exports