<!-- 球队管理 -->
<template>
    <div>
        <div class="mt-5">
            <el-table :data="teamList" style="width: 100%" :header-cell-style="{
                background: '#134f7a',
                color: '#fff',
                fontSize: '14px',
                height: '40px',
                width: '100%',
            }">
                <el-table-column prop="matchTeamId" label="序号" align="center">
                </el-table-column>
                <el-table-column prop="logo" label="球队logo" align="center">
                    <template slot-scope="scope">
                        <el-image :src="scope.row.logo" style="width: 50px;height:50px"
                            @click="editLogo(scope.row)"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="teamName" label="球队名称" align="center"></el-table-column>
                <el-table-column prop="captainName" label="队长姓名" align="center"></el-table-column>
                <el-table-column prop="signTime" label="报名时间" align="center"></el-table-column>
                <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
                <el-table-column prop="teamGroupName" label="分组" align="center">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.teamGroupName" placeholder="请选择组别" @change="groupChange">
                            <el-option v-for="item in groupData" :key="item.teamGroupId" :label="item.teamGroupName"
                                :value="item.teamGroupName">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="retire(scope.row)">删除</el-button>
                        <el-button type="text" @click="commitGroup(scope.row)">保存</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style=" margin-top: 10px; padding: 10px; text-align: right">
                <el-pagination background layout="sizes, prev, pager, next, jumper" :current-page="pageInfo.page"
                    :page-sizes="[10, 20, 30, 40]" :page-size="pageInfo.pageSize" :total="pageInfo.count"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="用户查看">
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="球队logo">
                    <f-image-upload v-model="form.logo" :width="'150px'" :height="'150px'"></f-image-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveLogo">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import FImageUpload from '@/common/components/Upload/f-image-upload';
export default {
    components: {
        FImageUpload,
    },
    data() {
        return {
            pageInfo: {
                page: 1,
                pageSize: 12,
                count: 0,
            },
            dialogVisible: false,
            formData: {},
            teamList: [],
            Jersey: [{
                value: "红色",
                label: "红色"
            },
            {
                value: "橙色",
                label: "橙色"
            },
            {
                value: "黄色",
                label: "黄色"
            },
            {
                value: "绿色",
                label: "绿色"
            },
            {
                value: "蓝色",
                label: "蓝色"
            },
            {
                value: "黑色",
                label: "黑色"
            },
            {
                value: "紫色",
                label: "紫色"
            }],
            dropdownName: "万象足球杯联赛",
            matchList: [],
            matchInfo: {},
            teamGroupId: "",
            groupData: [],
            form: {}
        };
    },
    mounted() {
        this.matchInfo.matchId = this.$route.query.matchId
        this.getTeamList()
        this.getTeamGroupList()
    },
    methods: {
        // 头部组件切换比赛，加载接口数据
        // changeMatch(match) {
        //     this.matchInfo = match;
        //     this.getTeamList(),
        //         this.getTeamGroupList()
        // },
        // 获取球队
        async getTeamList() {
            const params = {
                page: this.pageInfo.page,
                pageSize: this.pageInfo.pageSize,
            };
            const { res } = await this.$http.get(`/Match/TeamList/${this.matchInfo.matchId}`, { params });
            this.teamList = res.data.list || [];
            this.pageInfo.count = res.data.total;

        },
        // 分页
        handleSizeChange(val) {
            this.pageInfo.pageSize = val;
            this.getTeamList();
        },
        handleCurrentChange(val) {
            this.pageInfo.page = val;
            console.log(1111, val)
            this.getTeamList();
        },
        // 获取组别
        async getTeamGroupList() {
            const { res } = await this.$http.get(`/Match/TeamGroupList/${this.matchInfo.matchId}`);
            this.groupData = res.data || [];
        },
        // 选择组别
        groupChange(val) {
            this.teamList.teamGroupId = this.groupData.find(groupData => {
                return groupData.teamGroupName === val;
            }).teamGroupId;
        },
        // 保存球队组别
        commitGroup(row) {
            this.$confirm('确定要保存该球队的组别吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                const { res } = await this.$http.post(`/Match/SetTeamGroup/${row.matchTeamId}/${this.teamList.teamGroupId}`);
                if (res.isSuccess) {
                    this.$showSuccess('发布成功');
                    this.dialogVisibleMatch = false
                    this.getTeamList();
                }
            });
        },
        // 修改球队logo
        editLogo(row) {
            this.dialogVisible = true
            this.form = row
        },
        // 保存球队logo
        saveLogo() {
            this.$confirm('确定要修改该球队logo吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                const { res } = await this.$http.post(`/Match/UpdateTeamLogo/${this.form.matchTeamId}?logo=${this.form.logo}`);
                if (res.isSuccess) {
                    this.$showSuccess('发布成功');
                    this.dialogVisible = false
                    this.getTeamList();
                }
            });

        },
        // 删除球队
        retire(row) {
            this.$confirm('点击删除后，该球队信息将被清理，确认删除吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { res } = await this.$http.post(`/Match/DeleteTeam/${this.matchInfo.matchId}/${row.matchTeamId}`);
                if (res.isSuccess) {
                    this.$showSuccess('发布成功');
                    this.getTeamList();
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.cardinal {
    margin-top: 30px;
}

.addMatch {
    margin-left: 30px;
    font-size: 16px;
    color: #000;

}
</style>